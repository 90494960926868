import axios from "axios";
import React, { useEffect, useState } from "react";
import { selectUser } from "../slices/userSlice";
import { useSelector } from "react-redux";
import { styled, lighten, darken } from "@mui/system";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Wrap from "./Wrap";
import "../css/AlterTable.css";
import MuiTable from "./MuiTable";
import Button from "@mui/material/Button";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { GroupHeader, GroupItems } from "../App";
import "../css/InsertData.css";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import Alert from "@mui/material/Alert";
import moment from "moment";

export default function InsertData() {
  const user = useSelector((state) => selectUser(state));
  
  const [tables, setTables] = useState([]);
  useEffect(() => {
    (async () => {
      const response = await axios.get("/api/table/all", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const res = response?.data;
      if (res.status) {
        setTables(res.data.tables);
      }
    })();
  }, []);
  
  const [tableInfo, setTableInfo] = useState([]);
  const [hasCreateTime, setHasCreateTime] = useState(false);
  const [hasUpdateTime, setHasUpdateTime] = useState(false);
  const [name, setName] = useState("");
  const getTableInfo = async () => {
    const response = await axios.get(`/api/table/${name}`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    const res = response?.data;
    if (res.status) {
      var temp = res.data.columns.filter(
        (one) =>
          one.name !== "id" &&
          one.name !== "create_time" &&
          one.name !== "update_time"
      ); // 不显示id, create_time, update_time
      var temp = res.data.columns.filter((one) => {
        if (one.name === "id") {
          return false;
        }
        if (one.name === "create_time") {
          setHasCreateTime(true);
          return false;
        }
        if (one.name === "update_time") {
          setHasUpdateTime(true);
          return false;
        }
        return true;
      }); // 不显示id, create_time, update_time
      setTableInfo(temp);
      var dic = {};
      temp.forEach((one) => {
        dic[one.name] = "";
      })
      setDatas([dic]);
    }
  };
  useEffect(() => {
    if (name === "") return;
    getTableInfo();
  }, [name]);

  const [datas, setDatas] = useState([]);
  const del = (index) => {
    const newDatas = [...datas];
    newDatas.splice(index, 1);
    setDatas(newDatas);
  };
  const addOne = () => {
    setDatas([
      ...datas,
      tableInfo.reduce((acc, cur) => {
        acc[cur.name] = cur.value;
        return acc;
      }),
    ]);
  };
  const changeValue = (row, key, value) => {
    const newDatas = [...datas];
    newDatas[row][key] = value;
    setDatas(newDatas);
  };

  const [alertText, setAlertText] = useState("");
  const submit = async () => {
    var data = {};
    data[name] = datas;
    if (hasCreateTime) {
      data[name].map((one) => {
        one.create_time = moment().format("YYYY-MM-DD HH:mm:ss");
        return one;
      });
    }
    if (hasUpdateTime) {
      data[name].map((one) => {
        one.update_time = moment().format("YYYY-MM-DD HH:mm:ss");
        return one;
      });
    }
    data = {
      jsonString: JSON.stringify(data),
    }
    try {
      const response = await axios.post("/api/table/insert", JSON.stringify(data), {
        method: "POST",
      });
      const res = response?.data;
      if (res.status) {
        setOpenSuccess(true);
        setName("");
        setTableInfo([]);
        setDatas([]);
      } else {
        setOpenError(true);
      }
      setAlertText(res.message);
    } catch (error) {
      setOpenError(true);
      setAlertText(error.response.data);
    }
  };
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
    setOpenError(false);
  };
  return (
    <div className="insert_data">
      <Wrap>
        <FormControl
          className="table_name"
          variant="standard"
          sx={{ m: 1, minWidth: 120, margin: 0, marginLeft: 5 }}
        >
          <Autocomplete
            id="grouped-demo"
            options={tables}
            //   groupBy={(option) => option}
            getOptionLabel={(option) => option}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Table Name" />
            )}
            renderGroup={(params) => (
              <li key={params.key}>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
            value={name}
            onChange={(e, newValue) => setName(newValue)}
          />
        </FormControl>
        {datas.map((dic, row) => (
          <div className="row" key={row}>
            {Object.keys(dic).map((key, col) => (
              <div className="data_row" key={key}>
                <div className="card">
                  <div className="detail">
                    <label className="label">{key}</label>
                    <InputText
                      className="input"
                      value={dic.key}
                      onChange={(e) => changeValue(row, key, e.target.value)}
                    />
                  </div>
                </div>
              </div>
            ))}
            <Button
              variant="contained"
              className="del_btn"
              onClick={() => del(row)}
              color="error"
            >
              删除
            </Button>
          </div>
        ))}

        <div className="btn_div">
          <Button
            className="btn_add"
            variant="contained"
            onClick={addOne}
            disabled={name === ""}
          >
            新增一列
          </Button>
          <Button
            className="btn_create"
            variant="contained"
            onClick={submit}
            color="success"
          >
            新增
          </Button>
        </div>

        <Snackbar
          open={openSuccess}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {alertText}
          </Alert>
        </Snackbar>

        <Snackbar
          open={openError}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {alertText}
          </Alert>
        </Snackbar>
      </Wrap>
    </div>
  );
}
