import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {
    // UserId: null,
    Username: null,
    Password: null,
    Token: null,
  },
};

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser } = userSlice.actions;

export const selectUser = (state) => state.user.user;

export default userSlice.reducer;
