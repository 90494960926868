import React from "react";
import "../css/DividerCustom.css";
import { Divider } from "@mui/material";

export default function DividerCustom() {
  return (
    <div className="divider">
      <Divider />
    </div>
  );
}
