// Define an array of User objects
export const fakeData = [
  {
    id: "1",
    title: "公告",
    content: "本地测试啊啊啊本地测试啊啊啊本地测试啊啊啊本地测试啊啊啊本地测试啊啊啊 ",
    notice_type: "normal",
    start_time: 0,
    end_time: 1656584758,
  },
];

// Define an array of US states
export const NoticeType = [
  "normal",
];
