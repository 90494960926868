import React from 'react'
import '../css/Wrap.css'

export default function Wrap({ children }) {
  return (
    <div className='wrap_main'>
        <div className='content'>
            {children}
        </div>
    </div>
  )
}
