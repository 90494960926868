import React, { useState } from "react";
import Login from "@react-login-page/page2";
import defaultBannerImage from "@react-login-page/page2/banner-image";
import LoginPage, {
  Email,
  Password,
  Submit,
  Title,
  Logo,
  Reset,
} from "@react-login-page/page2";
import LoginLogo from "react-login-page/logo";
import { setUser } from "../slices/userSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Alert, Snackbar } from "@mui/material";

export default function Demo() {
  const from = useLocation().state?.from?.pathname || "/";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [alertText, setAlertText] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "api/user/login",
        JSON.stringify({ username, password }),
        {
          method: "POST",
        }
      );
      const data = await response.data;
      if (!data.status) {
        setAlertText(data.message);
        setOpenError(true);
        return;
      }
      dispatch(setUser({ username, password, token: data.data.Token }));
      // Cookies.set('refreshToken', user.refreshToken);
      // Cookies.set('accessToken', user.accessToken);
      axios.defaults.headers.put["Authorization"] = `Bearer ${data.data.Token}`;
      axios.defaults.headers.delete["Authorization"] = `Bearer ${data.data.Token}`;
      axios.defaults.headers.post["Authorization"] = `Bearer ${data.data.Token}`;
      axios.defaults.headers.get["Authorization"] = `Bearer ${data.data.Token}`;
      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        setAlertText("No Server Response");
      } else {
        setAlertText("Failed");
      }
    }
  };
  const [openError, setOpenError] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenError(false);
  };
  return (
    <LoginPage style={{ height: "100vh", width: "100vw" }}>
      <Login.Banner>
        <img src={defaultBannerImage} />
      </Login.Banner>
      <Email
        index={0}
        placeholder="请输入用户名"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <Password
        index={1}
        placeholder="请输入密码"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Submit onClick={(e) => handleSubmit(e)}>登录</Submit>
      <Title visible={false} />
      <Logo>
        <LoginLogo />
      </Logo>

      <Snackbar
        open={openError}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {alertText}
        </Alert>
      </Snackbar>
    </LoginPage>
  );
}
