import React, { useState } from "react";
import "../css/TableConfigure.css";
import TextField from "@mui/material/TextField";
import EachColumn from "./EachColumn";
import Button from "@mui/material/Button";
import { typeOptions } from "../public/config";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Wrap from "./Wrap";
import { useSelector } from "react-redux";
import { selectUser } from "../slices/userSlice";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CreateTable() {
  const user = useSelector((state) => selectUser(state));

  const [name, setName] = useState("");
  const [columns, setColumns] = useState([
    {
      name: "",
      type: typeOptions[0],
      notNull: false,
      default: "",
    },
  ]);

  const addOne = () => {
    setColumns([
      ...columns,
      {
        name: "",
        type: typeOptions[0],
        notNull: false,
        default: "",
      },
    ]);
  };

  const [alertText, setAlertText] = useState("");
  const submit = async () => {
    var data = {
      Data: [
        {
          TableName: name,
          Schema: columns.map((one, index) => ({
            name: one.name,
            type: `${one.type.value}${one.notNull ? " NOT NULL" : ""}${
              one.default ? ` DEFAULT '${one.default}'` : ""
            }`,
          })),
        },
      ],
    };
    data.Data[0].Schema = [{
      name: "id",
      type: "int NOT NULL AUTO_INCREMENT PRIMARY KEY",
    }, ...data.Data[0].Schema]
    try {
      const response = await axios.post("/api/table/create", JSON.stringify(data), {
        method: "POST",
      });
      const res = response?.data;
      if (res.status) {
        setOpenSuccess(true);
        setColumns([
          {
            name: "",
            type: typeOptions[0],
            notNull: false,
            default: "",
          },
        ]);
        await axios.get(`/api/table/proto/${name}`);
        setName("");
      } else {
        setOpenError(true);
      }
      setAlertText(res.message);
    } catch (error) {
      setOpenError(true);
      setAlertText(error.response.data);
    }
  };

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
    setOpenError(false);
  };
  return (
    <div className="table_configure">
      <Wrap>
        <TextField
          className="table_name"
          id="standard-basic"
          required
          label="Table Name"
          variant="standard"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {columns.map((one, index) => (
          <EachColumn
            key={index}
            // isPrimary={index === 0}
            index={index}
            columns={columns}
            setColumns={setColumns}
          />
        ))}
        <div className="btn_div">
          <Button className="btn_add" variant="contained" onClick={addOne}>
            新增一列
          </Button>
          <Button
            className="btn_create"
            variant="contained"
            onClick={submit}
            color="success"
          >
            创建
          </Button>
        </div>

        <Snackbar
          open={openSuccess}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {alertText}
          </Alert>
        </Snackbar>

        <Snackbar
          open={openError}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {alertText}
          </Alert>
        </Snackbar>
      </Wrap>
    </div>
  );
}
