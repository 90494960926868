import React from 'react'
import Nav from './Nav'
import '../css/Home.css'

export default function Home() {
  return (
    <div>
        <Nav />
    </div>
  )
}
