import React, { useEffect, useState } from "react";
import "../css/TableConfigure.css";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import { styled, lighten, darken } from "@mui/system";
import FormControlLabel from "@mui/material/FormControlLabel";
import { typeOptions } from "../public/config";
import Checkbox from "@mui/material/Checkbox";
import "../css/EachColumn.css";
import Button from "@mui/material/Button";
import { GroupHeader, GroupItems } from "../App";

export default function EachColumn({ isPrimary, index, columns, setColumns, update }) {
  const del = () => {
    const newColumns = [...columns];
    newColumns.splice(index, 1);
    setColumns(newColumns);
  };

  const setName = (name) => {
    const newColumns = [...columns];
    newColumns[index] = {
      name,
      type: columns[index].type,
      notNull: columns[index].notNull,
      default: columns[index].default,
      comment: columns[index].comment,
    };
    setColumns(newColumns);
  };
  const setType = (type) => {
    const newColumns = [...columns];
    newColumns[index] = {
      name: columns[index].name,
      type,
      notNull: columns[index].notNull,
      default: columns[index].default,
      comment: columns[index].comment,
    };
    setColumns(newColumns);
  };
  const setNotNull = (notNull) => {
    const newColumns = [...columns];
    newColumns[index] = {
      name: columns[index].name,
      type: columns[index].type,
      notNull,
      default: columns[index].default,
      comment: columns[index].comment,
    };
    setColumns(newColumns);
  };
  const setDefault = (defaultVal) => {
    const newColumns = [...columns];
    newColumns[index] = {
      name: columns[index].name,
      type: columns[index].type,
      notNull: columns[index].notNull,
      default: defaultVal,
      comment: columns[index].comment,
    };
    setColumns(newColumns);
  };
  const setComment = (comment) => {
    const newColumns = [...columns];
    newColumns[index] = {
      name: columns[index].name,
      type: columns[index].type,
      notNull: columns[index].notNull,
      default: columns[index].default,
      comment,
    };
    setColumns(newColumns);
  }

  const selectWidth = update ? 220: 240;
  const textFiledWidth = update ? 150: 180;
  return (
    <div className="each_column">
      <TextField
        id="standard-basic"
        required
        label={isPrimary ? "主键" : "字段"}
        variant="standard"
        value={columns[index].name}
        onChange={(e) => setName(e.target.value)}
        sx={{ width: textFiledWidth }}
      />
      <FormControl
        variant="standard"
        sx={{ m: 1, minWidth: 120, margin: 0, marginLeft: 5, width: selectWidth }}
      >
        <Autocomplete
          id="grouped-demo"
          options={typeOptions}
          groupBy={(option) => option.groupName}
          getOptionLabel={(option) => option.type}
          renderInput={(params) => (
            <TextField {...params} label="类型" />
          )}
          renderGroup={(params) => (
            <li key={params.key}>
              <GroupHeader>{params.group}</GroupHeader>
              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
          value={columns[index].type}
          onChange={(e, newValue) => setType(newValue)}
        />
      </FormControl>
      <FormControlLabel
        className="check"
        control={<Checkbox />}
        label="非空"
        checked={columns[index].notNull}
        onChange={(e) => setNotNull(e.target.checked)}
      />
      <TextField
        id="standard-basic"
        label="默认值"
        variant="standard"
        value={columns[index].default}
        onChange={(e) => setDefault(e.target.value)}
        sx={{ width: textFiledWidth }}
      />
      {update && <TextField
        className="comment"
        id="standard-basic"
        label="计算公式"
        variant="standard"
        value={columns[index].comment}
        onChange={(e) => setComment(e.target.value)}
        sx={{ width: textFiledWidth }}
        />}
      {!isPrimary && (
        <Button
          variant="contained"
          className="del_btn"
          onClick={del}
          color="error"
        >
          删除
        </Button>
      )}
    </div>
  );
}
