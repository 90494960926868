import { InputNumber } from "primereact/inputnumber";
import React, { useState } from "react";
import "../css/GameItem.css";

export default function GameItem({ name, min, max, index, values, setValues }) {
  const onChange = (newValue) => {
    let temp = [...values];
    temp[index].value = newValue;
    setValues(temp);
  }
  return (
    <div className="game_item flex-auto">
      <label htmlFor="minmax-buttons" className="font-bold block mb-2">
        {name}
      </label>
      <InputNumber
        className="input"
        inputId="minmax-buttons"
        value={values[index].value}
        onValueChange={(e) => onChange(e.value)}
        mode="decimal"
        showButtons
        min={min}
        max={max}
      />
    </div>
  );
}
