import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '../slices/userSlice';

const RequireAuth = () => {
  const user = useSelector((state) => selectUser(state));
  const location = useLocation();
  // return <Outlet />
  return user?.Token !== null ? (
    <Outlet />
  ) : (
    <Navigate to='/login' state={{ from: location }} replace />
  );
};

export default RequireAuth;
