import { useMemo, useState } from "react";
import {
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { NoticeType } from "./makeData";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import moment from "moment";

const key = "bulletins";
const queryClient = new QueryClient();

const Example = () => {
  const columns = [
    {
      accessorKey: "Id",
      header: "Id",
      enableEditing: false,
      size: 80,
    },
    {
      accessorKey: "Title",
      header: "Title",
      muiEditTextFieldProps: {
        required: true,
      },
    },
    {
      accessorKey: "Content",
      header: "Content",
      muiEditTextFieldProps: {
        type: "text",
        required: true,
      },
      muiTableBodyCellProps: {
      },
    },
    {
      accessorKey: "NoticeType",
      header: "Notice Type",
      editVariant: "select",
      editSelectOptions: NoticeType,
      muiEditTextFieldProps: {
        select: true,
      },
    },
    {
      accessorKey: "StartTime",
      header: "Start Time",
      muiEditTextFieldProps: {
        type: "datetime-local",
        required: true,
      },
    },
    {
      accessorKey: "EndTime",
      header: "End Time",
      muiEditTextFieldProps: {
        type: "datetime-local",
        required: true,
      },
    },
  ];

  //call CREATE hook
  const { mutateAsync: createBulletin, isPending: isCreatingBulletin } =
    useCreateBulletin();
  //CREATE action
  const handleCreateBulletin = async ({ values, table }) => {
    const maxIdObject = fetchedBulletins.reduce((maxObject, currentObject) => {
      if (currentObject.Id > maxObject.Id) {
        return currentObject;
      }
      return maxObject;
    }, fetchedBulletins[0]);
    if (typeof maxIdObject === "undefined") {
      values.Id = 1;
    } else {
      values.Id = maxIdObject.Id + 1;
    }
    values.StartTime = moment(values.StartTime).format("YYYY-MM-DD HH:mm:ss");
    values.EndTime = moment(values.EndTime).format("YYYY-MM-DD HH:mm:ss");
    values.StartTimestamp = moment(values.StartTime).valueOf()/1000;
    values.EndTimestamp = moment(values.EndTime).valueOf()/1000;
    await createBulletin(values);
    table.setCreatingRow(null); //exit creating mode
  };

  //call READ hook
  const {
    data: fetchedBulletins = [],
    isError: isLoadingBulletinsError,
    isFetching: isFetchingBulletins,
    isLoading: isLoadingBulletins,
  } = useGetBulletins();

  //call UPDATE hook
  const { mutateAsync: updateBulletin, isPending: isUpdatingBulletin } =
    useUpdateBulletin();
  //UPDATE action
  const handleSaveBulletin = async ({ values, table }) => {
    values.StartTime = moment(values.StartTime).format("YYYY-MM-DD HH:mm:ss");
    values.EndTime = moment(values.EndTime).format("YYYY-MM-DD HH:mm:ss");
    values.StartTimestamp = moment(values.StartTime).valueOf()/1000;
    values.EndTimestamp = moment(values.EndTime).valueOf()/1000;
    await updateBulletin(values);
    table.setEditingRow(null); //exit editing mode
  };

  //call DELETE hook
  const { mutateAsync: deleteBulletin, isPending: isDeletingBulletin } =
    useDeleteBulletin();
  //DELETE action
  const openDeleteConfirmModal = (row) => {
    if (window.confirm("确认删除?")) {
      deleteBulletin(row.original.Id);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: fetchedBulletins,
    createDisplayMode: "row", // ('modal', and 'custom' are also available)
    editDisplayMode: "row", // ('modal', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    getRowId: (row) => row.id,
    muiTableBodyCellProps: {
        sx: {
            wordWrap: 'break-word',
        }
    },
    muiToolbarAlertBannerProps: isLoadingBulletinsError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    onCreatingRowSave: handleCreateBulletin,
    onEditingRowSave: handleSaveBulletin,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="contained"
        onClick={() => {
          table.setCreatingRow(true); //simplest way to open the create row modal with no default values
          //or you can pass in a row object to set default values with the `createRow` helper function
          // table.setCreatingRow(
          //   createRow(table, {
          //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
          //   }),
          // );
        }}
      >
        创建公告
      </Button>
    ),
    state: {
      isLoading: isLoadingBulletins,
      isSaving: isCreatingBulletin || isUpdatingBulletin || isDeletingBulletin,
      showAlertBanner: isLoadingBulletinsError,
      showProgressBars: isFetchingBulletins,
    },
  });

  return <MaterialReactTable table={table} />;
};

//READ hook (get users from api)
function useGetBulletins() {
  return useQuery({
    queryKey: [key],
    queryFn: async () => {
      const response = await axios.get("/api/bulletin/all/1");
      const res = response?.data;
      if (res.status) {
                return res.data.data.map((bulletin) => ({
          ...bulletin,
          StartTime: moment(bulletin.StartTime*1000).format("YYYY-MM-DD HH:mm:ss"),
          EndTime: moment(bulletin.EndTime*1000).format("YYYY-MM-DD HH:mm:ss"),
        }));
      }
      return [];
    },
    refetchOnWindowFocus: false,
  });
}

//CREATE hook (post new user to api)
function useCreateBulletin() {
  return useMutation({
    mutationFn: async (bulletin) => {
      const response = await axios.post(
        `/api/bulletin/create/1`,
        JSON.stringify({
          ...bulletin,
          StartTime: bulletin.StartTimestamp,
          EndTime: bulletin.EndTimestamp,
        })
      );
      return bulletin;
    },
    onSuccess: (newBulletinInfo) => {
      queryClient.setQueryData([key], (prevBulletins) => [
        ...prevBulletins,
        newBulletinInfo,
      ]);
    },
  });
}

//UPDATE hook (put user in api)
function useUpdateBulletin() {
  return useMutation({
    mutationFn: async (bulletin) => {
      const response = await axios.put(
        `/api/bulletin/update/1/${bulletin.Id}`,
        JSON.stringify({
          ...bulletin,
          StartTime: bulletin.StartTimestamp,
          EndTime: bulletin.EndTimestamp,
        })
      );
      return bulletin;
    },
    onSuccess: (newBulletinInfo) => {
      queryClient.setQueryData([key], (prevBulletins) =>
        prevBulletins?.map((prevBulletin) =>
          prevBulletin.Id === newBulletinInfo.Id
            ? newBulletinInfo
            : prevBulletin
        )
      );
    },
  });
}

//DELETE hook (delete user in api)
function useDeleteBulletin() {
  return useMutation({
    mutationFn: async (Id) => {
      const response = await axios.delete(`/api/bulletin/delete/1/${Id}`);
      return Id;
    },
    onSuccess: (Id) => {
      queryClient.setQueryData([key], (prevBulletins) =>
        prevBulletins?.filter((bulletin) => bulletin.Id !== Id)
      );
    },
  });
}


const ExampleWithProviders = () => (
  //Put this with your other react-query providers near root of your app
  <QueryClientProvider client={queryClient}>
    <Example />
  </QueryClientProvider>
);

export default ExampleWithProviders;
