import dayjs from "dayjs";
import axios from "axios";
import CreateTable from "../components/CreateTable";
import AlterTable from "../components/AlterTable";
import InsertData from "../components/InsertData";
import ShowData from "../components/ShowData";
import HomeIcon from '@mui/icons-material/Home';
import CreateIcon from '@mui/icons-material/Create';
import AddIcon from '@mui/icons-material/Add';
import BuildIcon from '@mui/icons-material/Build';
import ExampleWithProviders from "../components/CRUD";
import GMManagement from "../components/GMManagement";
var isoWeek = require('dayjs/plugin/isoWeek')
dayjs.extend(isoWeek)

export const timeItems = [
  {
    label: "昨日",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(1, "day"), today.subtract(1, "day"), "昨日"];
    },
  },
  {
    label: "今日",
    getValue: () => {
      const today = dayjs();
      return [today, today, "今日"];
    },
  },
  {
    label: "上周",
    getValue: () => {
      const prevWeek = dayjs().subtract(7, "day");
      return [
        prevWeek.startOf("isoWeek"),
        prevWeek.endOf("isoWeek"),
        "上周"
      ];
    },
  },
  {
    label: "本周",
    getValue: () => {
      const today = dayjs();
      return [
        today.startOf('isoWeek'),
        today,
        "本周"
      ];
    },
  },
  {
    label: "上月",
    getValue: () => {
      const today = dayjs();
      const prevMonth = today.subtract(1, "month");
      return [prevMonth.startOf("month"), prevMonth.endOf("month"), "上月"];
    },
  },
  {
    label: "本月",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today, "本月"];
    },
  },
  {
    label: "过去7天",
    getValue: () => {
      const yesterday = dayjs().subtract(1, "day");
      return [yesterday.subtract(7, "day"), yesterday, "过去7天"];
    },
  },
  {
    label: "最近7天", // ?
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, "day"), today, "最近7天"];
    },
  },
  {
    label: "过去30天",
    getValue: () => {
      const yesterday = dayjs().subtract(1, "day");
      return [yesterday.subtract(30, "day"), yesterday, "过去30天"];
    },
  },
  {
    label: "最近30天", // ?
    getValue: () => {
      const today = dayjs();
      return [today.subtract(30, "day"), today, "最近30天"];
    },
  },
];

export const tagConfig = [
  {
    name: '注册玩家数',
    unit: '人',
    x_name: '玩家注册.触发玩家数',
    url: `api/activity/register`,
    options: [
      { value: "day", label: "按天" },
      { value: "week", label: "按周" },
      { value: "month", label: "按月" },
    ],
  },
  {
    name: '登录玩家数',
    unit: '人',
    x_name: '玩家登录.触发玩家数',
    url: `api/activity/login/counts`,
    options: [
      { value: "day", label: "按天" },
      { value: "week", label: "按周" },
      { value: "month", label: "按月" },
    ], 
  },
  {
    name: '登录玩家次数',
    unit: '人',
    x_name: '玩家登录.触发玩家次数',
    url: `api/activity/login/times`,
    options: [
      { value: "day", label: "按天" },
      { value: "week", label: "按周" },
      { value: "month", label: "按月" },
    ], 
  },
  {
    name: '玩家在线时长',
    unit: '秒',
    x_name: '玩家在线.触发玩家在线时长',
    url: `api/activity/online`,
    options: [
      { value: "day", label: "按天" },
      { value: "month", label: "按月" },
    ], 
  },
  {
    name: '峰值在线人数',
    unit: '人',
    x_name: '峰值在线人数.触发峰值在线人数',
    url: `api/activity/online_history`,
    options: [
      { value: "day", label: "按天" },
      { value: "month", label: "按月" },
      { value: "top", label: "最高" },
    ], 
  },
]


axios.defaults.baseURL = process.env.REACT_APP_URL;
// axios.defaults.baseURL = "https://192.168.20.156:8000";
// axios.defaults.baseURL = "https://localhost:8000";
// axios.defaults.baseURL = "https://igcgame.net";
axios.defaults.headers.get["Content-Type"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.delete["Content-Type"] = "application/json";

export const typeOptions = [
  {
    groupName: "常见",
    type: "int",
    value: "int",
  },
  {
    groupName: "常见",
    type: "float",
    value: "float",
  },
  {
    groupName: "常见",
    type: "double",
    value: "double",
  },
  {
    groupName: "常见",
    type: "datetime",
    value: "datetime",
  },
  {
    groupName: "常见",
    type: "varchar(255)",
    value: "varchar(255)",
  },
];

export const navConfig = [
  {
    text: "GM后台",
    component: <GMManagement />,
    icon: <AddIcon />,
  },
  {
    text: "公告管理",
    component: <ExampleWithProviders/>,
    icon: <HomeIcon />,
  },
  {
    text: "创建表",
    component: <CreateTable />,
    icon: <BuildIcon />,
  },
  {
    text: "修改表",
    component: <AlterTable />,
    icon: <CreateIcon />,
  },
  {
    text: "插入数据",
    component: <InsertData />,
    icon: <AddIcon />,
  },
];

export const IgcGameItems = [
  {
    id: 1,
    initValue: 0,
    name: "钞票",
    min: 0,
    max: 1000000000,
  },
  {
    id: 2,
    initValue: 0,
    name: "钻石",
    min: 0,
    max: 1000000000,
  },
  {
    id: 3,
    initValue: 0,
    name: "富翁币",
    min: 0,
    max: 1000000000,
  },
  {
    id: 4,
    initValue: 0,
    name: "Igc games 平台币",
    min: 0,
    max: 1000000000,
  },
  {
    id: 5,
    initValue: 0,
    name: "安山币",
    min: 0,
    max: 1000000000,
  },
  {
    id: 501,
    initValue: 0,
    name: "慈善等级（等级）",
    min: 0,
    max: 100,
  },
  {
    id: 502,
    initValue: 0,
    name: "服不服指数（战斗力）",
    min: 0,
    max: 1000000000,
  },
];