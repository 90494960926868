import axios from "axios";
import React, { useEffect, useState } from "react";
import { selectUser } from "../slices/userSlice";
import { useSelector } from "react-redux";
import { styled, lighten, darken } from "@mui/system";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Wrap from "./Wrap";
import "../css/AlterTable.css";
import MuiTable from "./MuiTable";
import Button from "@mui/material/Button";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { typeOptions } from "../public/config";
import EachColumn from "./EachColumn";
import { GroupHeader, GroupItems } from "../App";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AlterTable() {
  const user = useSelector((state) => selectUser(state));
  const [tables, setTables] = useState([]);
  useEffect(() => {
    (async () => {
      const response = await axios.get("/api/table/all");
      const res = response?.data;
      if (res.status) {
        setTables(res.data.tables);
      }
    })();
  }, []);

  const [name, setName] = useState("");
  const [tableInfo, setTableInfo] = useState([]);
  const getTableInfo = async () => {
    const response = await axios.get(`/api/table/${name}`);
    const res = response?.data;
  console.log(res)
    if (res.status) {
      setTableInfo(res.data.columns);
    }
  };
  useEffect(() => {
    if (name === "") return;
    getTableInfo();
  }, [name]);

  const [columns, setColumns] = useState([]);
  const addOne = () => {
    setColumns([
      ...columns,
      {
        name: "",
        type: typeOptions[0],
        notNull: false,
        default: "",
        comment: "",
      },
    ]);
  };

  const [alertText, setAlertText] = useState("");
  const submit = async () => {
    const data = {
      TableName: name,
      Schema: columns.map((one, index) => ({
        Name: one.name,
        Type: `${one.type.value}${one.notNull ? " NOT NULL" : ""}${
          one.default ? ` DEFAULT '${one.default}'` : ""
        }`,
        Formular: one.comment,
      })),
    };
    try {
      const response = await axios.post("/api/table/alter", JSON.stringify(data), {
        method: "POST",
      });
      const res = response?.data;
      if (res.status) {
        setOpenSuccess(true);
        setColumns([]);
        getTableInfo();
        await axios.get(`/api/table/proto/${name}`);
      } else {
        setOpenError(true);
      }
      setAlertText(res.message);
    } catch (error) {
      setOpenError(true);
      setAlertText(error.response.data);
    }
  };

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
    setOpenError(false);
  };

  const refresh = async () => {
    try {
      const response = await axios.post(
        "/api/table/refresh",
        name,
        {
          method: "POST",
        }
      );
      const res = response?.data;
      if (res.status) {
        setOpenSuccess(true);
      } else {
        setOpenError(true);
      }
      setAlertText(res.message);
    } catch (error) {
      setOpenError(true);
      setAlertText(error.response.data);
    }
  };
  return (
    <div className="alter_table">
      <Wrap>
        <FormControl
          className="table_name"
          variant="standard"
          sx={{ m: 1, minWidth: 120, margin: 0, marginLeft: 5 }}
        >
          <Autocomplete
            id="grouped-demo"
            options={tables}
            //   groupBy={(option) => option}
            getOptionLabel={(option) => option}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Table Name" />
            )}
            renderGroup={(params) => (
              <li key={params.key}>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
            value={name}
            onChange={(e, newValue) => setName(newValue)}
          />
          <Button
            className="btn_refresh"
            variant="contained"
            onClick={refresh}
            disabled={name === ""}
          >
            计算
          </Button>
        </FormControl>
        <div className="muitable">
          <MuiTable className="muitable" data={tableInfo} />
        </div>

        {columns.map((one, index) => (
          <EachColumn
            key={index}
            isPrimary={false}
            index={index}
            columns={columns}
            setColumns={setColumns}
            update
          />
        ))}
        <div className="btn_div">
          <Button
            className="btn_add"
            variant="contained"
            onClick={addOne}
            disabled={name === ""}
          >
            新增一列
          </Button>
          <Button
            className="btn_create"
            variant="contained"
            onClick={submit}
            color="success"
          >
            修改
          </Button>
        </div>

        <Snackbar
          open={openSuccess}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {alertText}
          </Alert>
        </Snackbar>

        <Snackbar
          open={openError}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {alertText}
          </Alert>
        </Snackbar>
      </Wrap>
    </div>
  );
}
