import { configureStore } from '@reduxjs/toolkit';
import useReducer from './slices/userSlice';
import pageReducer from './slices/pageSlice';

// {"message": {"pressMessageSeq": 0}, "user": {"user": {"birthday": null, "gender": null, "image": null, "location": null, "name": null, "self_signature": null, "user_id": null}}}
export const store = configureStore({
  reducer: {
    user: useReducer,
    page: pageReducer,
  },
});
