import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { store } from "./store";
import RequireAuth from "./components/RequireAuth";
import Layout from "./components/Layout";
import Home from "./components/Home";
import Unauthorized from "./components/Unauthorized";
import Missing from "./components/Missing";
import Demo from "./components/Demo";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { styled, lighten, darken } from "@mui/system";
import { useTheme } from "@mui/material";
import PrimeTable2 from "./components/PrimeTable2";
import ExampleWithProviders from "./components/CRUD";

export var theme = {};
export const GroupHeader = styled("div")(() => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));
export const GroupItems = styled("ul")({
  padding: 0,
});
export default function App() {
  theme = useTheme();
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="login" element={<Demo />} />
            <Route path="unauthorized" element={<Unauthorized />} />
            {/* <Route path="demo" element={<PrimeTable2 />} /> */}
            {/* <Route path="crud" element={<ExampleWithProviders />} /> */}

            <Route element={<RequireAuth />}>
              <Route path="/" element={<Home />} />
            </Route>

            <Route path="*" element={<Missing />} />
          </Route>
        </Routes>
      </Router>
    </Provider>
  );
}
