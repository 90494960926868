import { createSlice } from "@reduxjs/toolkit";
import moment from "moment-timezone";

const initialState = {
  selectedDetail: {
    name: "",
    x_name: "",
    url: "",
  },
  selectedTimeZone: {
    name: "当地时间",
    code: "Asia/Shanghai",
    offSet: 8,
  },
  selectedDate: [moment().subtract(30, "days"), moment().subtract(1, "days")],
};

export const pageSlice = createSlice({
  name: "pageSlice",
  initialState,
  reducers: {
    setSelectedDetail: (state, action) => {
      state.selectedDetail = action.payload;
    },
    setSelectedTimeZone: (state, action) => {
      state.selectedTimeZone = action.payload;
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setSelectedDetail, setSelectedTimeZone, setSelectedDate } = pageSlice.actions;

export const selectSelectedDetail = (state) => state.page.selectedDetail;

export const selectSelectedTimeZone = (state) => state.page.selectedTimeZone;

export const selectSelectedDate = (state) => state.page.selectedDate;

export default pageSlice.reducer;
