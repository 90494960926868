import React, { useEffect, useState } from "react";
import {
  Alert,
  Autocomplete,
  Button,
  Divider,
  FormControl,
  Snackbar,
  TextField,
} from "@mui/material";
import { InputSwitch } from "primereact/inputswitch";
import "../css/GMManagement.css";
import GameItem from "./GameItem";
import { IgcGameItems } from "../public/config";
import { InputNumber } from "primereact/inputnumber";
import axios from "axios";
import DividerCustom from "./DividerCustom";
import { GroupHeader, GroupItems } from "../App";
import { Dropdown } from "primereact/dropdown";

export default function GMManagement() {
  const [userId, setUserId] = useState(0);
  const [checked, setChecked] = useState(false);
  const [values, setValues] = useState(
    IgcGameItems.map((item) => ({ id: item.id, value: item.initValue }))
  );
  const [itemId, setItemId] = useState(0);
  const [itemNumber, setItemNumber] = useState(0);

//   const servers = [
//     { zone_name: "腾讯云稳定服", zone_addr: "localhost", port: 5064 },
//     { zone_name: "内网服开发服", zone_addr: "localhost", port: 5064 },
//     { zone_name: "内网服0.73", zone_addr: "localhost", port: 5064 },
//   ];

  const [alertText, setAlertText] = useState("");
  const submit = async () => {
    // console.log(userId);
    // console.log(checked);
    // console.log(values);
    // console.log(itemId);
    // console.log(itemNumber);

    let flag = true;
    if (checked) {
      try {
        const response = await axios.post(
          `/api/gm/unlock_all_streets/${userId}`,
          JSON.stringify({}),
          {
            method: "POST",
          }
        );
        const res = response?.data;
        if (res.status) {
        } else {
          setOpenError(true);
          flag = false;
        }
      } catch (error) {
        setOpenError(true);
        setAlertText(error.response.data);
        flag = false;
      }
    }

    const data = values.map((item) => `${item.id}#${item.value}`);
    data.push(`${itemId}#${itemNumber}`);
    // console.log(data);
    try {
      const response = await axios.post(
        `/api/gm/add_goods/${userId}`,
        JSON.stringify(data),
        {
          method: "POST",
        }
      );
      const res = response?.data;
      if (res.status) {
      } else {
        setOpenError(true);
        flag = false;
      }
    } catch (error) {
      setOpenError(true);
      setAlertText(error.response.data);
      flag = false;
    }

    if (flag) {
      setAlertText("操作成功");
      setOpenSuccess(true);
    }
  };

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
    setOpenError(false);
  };
  return (
    <div className="div_gm_management">
      <div className="flex gap-8">
        <div className="div_username flex flex-column gap-2">
          <label htmlFor="">玩家id</label>
          <InputNumber
            className="input_username"
            aria-describedby="username-help"
            useGrouping={false}
            value={userId}
            onValueChange={(e) => setUserId(e.value)}
          />
        </div>
      </div>
      <DividerCustom />
      <div className="div_unlock">
        <label>解锁所有街道并升级建筑</label>
        <InputSwitch
          className="input_unlock"
          checked={checked}
          onChange={(e) => setChecked(e.value)}
        />
      </div>
      <DividerCustom />
      <div className="div_items">
        {IgcGameItems.map((item, index) => (
          <GameItem
            {...item}
            index={index}
            values={values}
            setValues={setValues}
          />
          //   <GameItem item={item} values={values} setValues={setValues} />
        ))}
      </div>
      <DividerCustom />
      <div className="flex gap-8">
        <div className="div_username flex flex-column gap-2">
          <label htmlFor="">道具id</label>
          <InputNumber
            className="input_username"
            aria-describedby="username-help"
            useGrouping={false}
            value={itemId}
            onValueChange={(e) => setItemId(e.value)}
          />
        </div>
        <div className="div_username flex flex-column gap-2">
          <label htmlFor="">道具数量</label>
          <InputNumber
            className="input_username"
            aria-describedby="username-help"
            value={itemNumber}
            onValueChange={(e) => setItemNumber(e.value)}
          />
        </div>
      </div>
      <DividerCustom />
      <div>
        <Button className="btn_add" variant="contained" onClick={submit}>
          提交
        </Button>
      </div>

      <Snackbar
        open={openSuccess}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {alertText}
        </Alert>
      </Snackbar>

      <Snackbar
        open={openError}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {alertText}
        </Alert>
      </Snackbar>
    </div>
  );
}
